
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { graphql } from "@/__generated__";
import ContainerPaper from "@/components/ContainerPaper";
import CopyTag from "@/components/copyTag";
import type { RangeValue } from "@/components/datePicker";
import RangerDatePicker from "@/components/datePicker";
import { ReactECharts } from "@/components/echart";
import { useQuery } from "@apollo/client";
import time from "@flyer/utils/time";
import { Box } from "@mui/material";
import { Radio, Typography } from "antd";
import { groupBy, keys, mapValues, orderBy, round, sumBy } from "lodash-es";
import { useMemo, useState } from "react";
const TeacherByDaysQuery = graphql(`
  query TeacherByDaysQuery(
    $filterBy: TeacherByDayFilter!
  ) {
    adminTeacherByDays(
      filterBy: $filterBy
    ) {
      id
      date
      total
    }
  }
`);
enum CompletedTestView {
    DAY = 0,
    WEEK = 1,
    MONTH = 2
}
export default function TeacherByDay() {
    const [date, setDate] = useState<RangeValue>([time().subtract(2, "week"), time()]);
    const [typeView, setTypeView] = useState(CompletedTestView.DAY);
    const { data, loading: loadingTest } = useQuery(TeacherByDaysQuery, {
        context: {
            v2: true
        },
        variables: {
            filterBy: {
                date: {
                    gte: date?.[0]?.startOf("d")?.toISOString(),
                    lt: date?.[1]?.endOf("d")?.toISOString()
                }
            }
        },
        skip: !date
    });
    const transferDataView = useMemo(() => {
        let groupByPeriod = groupBy(data?.adminTeacherByDays, (item) => time(item.date).format("YYYY-MM-DD"));
        if (typeView === CompletedTestView.MONTH) {
            groupByPeriod = groupBy(data?.adminTeacherByDays, (item) => time(item.date).format("YYYY-MM"));
        }
        if (typeView === CompletedTestView.WEEK) {
            groupByPeriod = groupBy(data?.adminTeacherByDays, (item) => {
                const t = time(item.date);
                return `${t.year()}-W${t.week()}`;
            });
        }
        return mapValues(groupByPeriod, (item) => {
            return {
                total: round(sumBy(item, "total")) || 0
            };
        });
    }, [data?.adminTeacherByDays, typeView]);
    const totalTrial = useMemo(() => sumBy(data?.adminTeacherByDays, "total") || 0, [data?.adminTeacherByDays]);
    const option = useMemo(() => {
        const listKey = orderBy(keys(transferDataView), (date) => {
            return date;
        }, "asc");
        const totalSeries = listKey.map((el) => transferDataView[el]?.total || 0);
        return {
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "shadow"
                }
            },
            xAxis: {
                type: "category" as const,
                axisTick: {
                    alignWithLabel: true
                },
                data: listKey
            },
            yAxis: {
                type: "value"
            },
            series: [
                {
                    name: "Total",
                    data: totalSeries,
                    type: "bar"
                },
            ]
        };
    }, [transferDataView]);
    return (<ContainerPaper>
      <Typography.Title level={2}>Teachers</Typography.Title>
      <Box sx={{
            marginBottom: "16px",
            display: "flex",
            gap: "8px",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap"
        }}>
        <CopyTag valueCopy={totalTrial.toString()} color="success">
          Total trial: {totalTrial}
        </CopyTag>
      </Box>
      <Box sx={{
            marginBottom: "16px",
            display: "flex",
            gap: "8px",
            justifyContent: "space-between",
            alignItems: "center"
        }}>
        <RangerDatePicker onChange={setDate} value={date}/>
        <Radio.Group value={typeView} onChange={(e) => {
            setTypeView(e.target.value as number);
        }}>
          <Radio.Button value={CompletedTestView.DAY}>Day</Radio.Button>
          <Radio.Button value={CompletedTestView.WEEK}>Week</Radio.Button>
          <Radio.Button value={CompletedTestView.MONTH}>Month</Radio.Button>
        </Radio.Group>
      </Box>
      <ReactECharts option={option} style={{ width: "100%", height: "500px" }} 
    // theme={'light'}
    loading={loadingTest}/>
    </ContainerPaper>);
}

    async function __Next_Translate__getStaticProps__195f0f70197__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/dashboard/components/TeacherByDay.tsx',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195f0f70197__ as getStaticProps }
  