import { Card, Typography } from 'antd';
import { head } from 'lodash-es';
import ContainerPaper from '@/components/ContainerPaper';
import { useGetUserFreeToMembership } from '@/graphql/generated/query';

function CardNumberUserFreeToMembership() {
  const { data, loading } = useGetUserFreeToMembership();

  const numberTotal = head(data?.user_free_to_membership)?.avg?.toFixed(3).replace('.', ',');

  return (
    <ContainerPaper>
      <Typography.Title level={2}>Information</Typography.Title>
      <Card
        loading={loading}
        title="Conversion Rate from Free Users to Membership"
        variant="borderless"
        style={{ width: 300, color: 'black', fontWeight: '600', fontSize: '20px' }}
      >
        {numberTotal}
      </Card>
    </ContainerPaper>
  );
}

export default CardNumberUserFreeToMembership;
