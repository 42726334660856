import { type ECElementEvent, getInstanceByDom, init } from "echarts";
import type { ECharts, EChartsOption, SetOptionOpts } from "echarts";
import { useEffect, useRef } from "react";
import type { CSSProperties } from "react";

export interface ReactEChartsProps {
  option: EChartsOption;
  style?: CSSProperties;
  settings?: SetOptionOpts;
  loading?: boolean;
  theme?: "light" | "dark";
  onChangeLegend?: (params: { [key: string]: boolean }) => void;
  onClick?: (params: ECElementEvent) => void;
}

export function ReactECharts({
  option,
  style,
  settings,
  loading,
  theme,
  onChangeLegend,
  onClick,
}: ReactEChartsProps): JSX.Element {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let chart: ECharts | undefined;
    if (chartRef.current !== null) {
      chart = init(chartRef.current, theme);
    }

    function resizeChart() {
      chart?.resize();
    }
    window.addEventListener("resize", resizeChart);

    return () => {
      chart?.dispose();
      window.removeEventListener("resize", resizeChart);
    };
  }, [theme]);

  useEffect(() => {
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      chart?.setOption(option, settings);
    }
  }, [option, settings]);

  useEffect(() => {
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      if (chart) {
        if (loading) {
          chart.showLoading();
        } else {
          chart.hideLoading();
        }
      }
    }
  }, [loading]);

  useEffect(() => {
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      if (chart) {
        chart.on("legendselectchanged", (params) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onChangeLegend?.(params?.selected as { [key: string]: boolean });
        });

        chart.on("click", (event) => {
          onClick?.(event);
        });
      }
    }
  }, [onChangeLegend, onClick]);

  return <div ref={chartRef} style={{ width: "100%", height: "100px", ...style }} />;
}
