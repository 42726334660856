
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import CardNumberUserFreeToMembership from "@/components/CardNumberUserFreeToMembership";
import StudyTimeReport from "@/components/StudyTimeReport";
import Membership from "@/components/membership";
import CompletedTest from "@/components/testCompleted";
import UnCompletedTest from "@/components/testUnCompleted";
import { Box } from "@mui/material";
import TeacherByDay from "./components/TeacherByDay";
function Dashboard() {
    return (<Box sx={{ display: "flex", flexDirection: "column", gap: 4, alignItems: "center" }}>
      <CardNumberUserFreeToMembership />
      <Membership />
      <TeacherByDay />
      <CompletedTest />
      <StudyTimeReport />
      <UnCompletedTest />
    </Box>);
}
export default Dashboard;

    async function __Next_Translate__getStaticProps__195f0f6ff53__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/dashboard/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195f0f6ff53__ as getStaticProps }
  